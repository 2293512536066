<template>
  <div class="createPost-container">
    <p class="notice-title">{{info.title}}</p>
    <div style="width:100%; height:1px; background-color:#ccc"></div>

    <div style="width:60%; margin-left:20%; margin-top:30px" v-if="showVideo">
      <videoPlayer
        class="video-player vjs-custom-skin"  
        ref="positiveVideoPlayer"
        :playsinline="true" 
        :options="positivePlayerOptions">
      </videoPlayer>
    </div>
    <div style="margin-top:20px;" v-html="info.content" />
  </div>
</template>

<script>
import { getDocInfo } from '@/api/basic/common'

export default {
  components: {},
  data() {
    return {
      id:'',
      info: {},
      video:{},
      showVideo:false,
      loading: false,

      positivePlayerOptions : {
                    playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
                    autoplay : false, //如果true,浏览器准备好时开始回放。
                    muted : false, // 默认情况下将会消除任何音频。
                    loop : false, // 视频一结束就重新开始。
                    preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language : 'zh-CN',
                    aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources : [ {
                        type : "",
                        //src : this.tempUrl//url地址
                        src: ''
                    } ],
                    poster : "", //你的封面地址
                    // width: document.documentElement.clientWidth,
                    notSupportedMessage : '视频暂时无法播放', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar : {
                        timeDivider : true,//当前时间和持续时间的分隔符
                        durationDisplay : true,//显示持续时间
                        remainingTimeDisplay : false,//是否显示剩余时间功能
                        fullscreenToggle : true  //全屏按钮
                    }
                }
    }
  },
  created() {
    const id = this.$route.query.id
    this.id = id
    this.getInfo()
  },
  methods: {
    getInfo() {
      getDocInfo({ id: this.$route.query.id }).then(response => {
        this.info = response.data
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.createPost-container {
  position: relative;
  padding:40px;
}
.notice-title{
  width:100%;
  display:block;
  text-align: center;
  line-height:40px;
  font-size:25px;
}
</style>
